import Proj1 from '../Assets/proj1.png'
import trial from '../Assets/trial.jpeg'

export const projectList = [
    {
        name: "MangaList Tracker ",
        image: Proj1,
        skills: 'HTML, CSS, JavaScript, PostGresSQL '
    },

  
]